// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-app-page-cancel-card-js": () => import("./../../../src/pages/app-page/CancelCard.js" /* webpackChunkName: "component---src-pages-app-page-cancel-card-js" */),
  "component---src-pages-components-brand-voucher-js": () => import("./../../../src/pages/components/brand-voucher.js" /* webpackChunkName: "component---src-pages-components-brand-voucher-js" */),
  "component---src-pages-components-c-1-js": () => import("./../../../src/pages/components/C1.js" /* webpackChunkName: "component---src-pages-components-c-1-js" */),
  "component---src-pages-components-c-2-js": () => import("./../../../src/pages/components/C2.js" /* webpackChunkName: "component---src-pages-components-c-2-js" */),
  "component---src-pages-components-c-3-js": () => import("./../../../src/pages/components/C3.js" /* webpackChunkName: "component---src-pages-components-c-3-js" */),
  "component---src-pages-components-c-4-js": () => import("./../../../src/pages/components/C4.js" /* webpackChunkName: "component---src-pages-components-c-4-js" */),
  "component---src-pages-components-c-5-js": () => import("./../../../src/pages/components/C5.js" /* webpackChunkName: "component---src-pages-components-c-5-js" */),
  "component---src-pages-components-c-6-js": () => import("./../../../src/pages/components/C6.js" /* webpackChunkName: "component---src-pages-components-c-6-js" */),
  "component---src-pages-components-c-7-js": () => import("./../../../src/pages/components/C7.js" /* webpackChunkName: "component---src-pages-components-c-7-js" */),
  "component---src-pages-components-c-8-js": () => import("./../../../src/pages/components/C8.js" /* webpackChunkName: "component---src-pages-components-c-8-js" */),
  "component---src-pages-components-c-9-js": () => import("./../../../src/pages/components/C9.js" /* webpackChunkName: "component---src-pages-components-c-9-js" */),
  "component---src-pages-components-header-js": () => import("./../../../src/pages/components/Header.js" /* webpackChunkName: "component---src-pages-components-header-js" */),
  "component---src-pages-components-loader-js": () => import("./../../../src/pages/components/Loader.js" /* webpackChunkName: "component---src-pages-components-loader-js" */),
  "component---src-pages-components-lottie-animation-js": () => import("./../../../src/pages/components/LottieAnimation.js" /* webpackChunkName: "component---src-pages-components-lottie-animation-js" */),
  "component---src-pages-components-partner-with-us-collab-detail-js": () => import("./../../../src/pages/components/partner-with-us/collab-detail.js" /* webpackChunkName: "component---src-pages-components-partner-with-us-collab-detail-js" */),
  "component---src-pages-components-partner-with-us-connect-cta-floating-js": () => import("./../../../src/pages/components/partner-with-us/connect-cta-floating.js" /* webpackChunkName: "component---src-pages-components-partner-with-us-connect-cta-floating-js" */),
  "component---src-pages-components-partner-with-us-hero-section-js": () => import("./../../../src/pages/components/partner-with-us/hero-section.js" /* webpackChunkName: "component---src-pages-components-partner-with-us-hero-section-js" */),
  "component---src-pages-components-partner-with-us-partner-contact-form-js": () => import("./../../../src/pages/components/partner-with-us/partner-contact-form.js" /* webpackChunkName: "component---src-pages-components-partner-with-us-partner-contact-form-js" */),
  "component---src-pages-components-partner-with-us-partner-reason-js": () => import("./../../../src/pages/components/partner-with-us/partner-reason.js" /* webpackChunkName: "component---src-pages-components-partner-with-us-partner-reason-js" */),
  "component---src-pages-components-partner-with-us-partner-result-js": () => import("./../../../src/pages/components/partner-with-us/partner-result.js" /* webpackChunkName: "component---src-pages-components-partner-with-us-partner-result-js" */),
  "component---src-pages-components-privacy-header-js": () => import("./../../../src/pages/components/PrivacyHeader.js" /* webpackChunkName: "component---src-pages-components-privacy-header-js" */),
  "component---src-pages-components-vkyc-js": () => import("./../../../src/pages/components/vkyc.js" /* webpackChunkName: "component---src-pages-components-vkyc-js" */),
  "component---src-pages-disclosures-account-deletion-js": () => import("./../../../src/pages/disclosures/account-deletion.js" /* webpackChunkName: "component---src-pages-disclosures-account-deletion-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partner-with-us-js": () => import("./../../../src/pages/partner-with-us.js" /* webpackChunkName: "component---src-pages-partner-with-us-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

